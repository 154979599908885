var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-main',[_c('v-app-bar',{staticStyle:{"margin-bottom":"10px"},attrs:{"height":"48","color":"white"}},[_c('v-toolbar-title',[_c('img',{attrs:{"src":require("@/assets/img/logo_0.png"),"height":"40","alt":"Logo"}}),_c('span',{staticClass:"ms-2 font-weight-bold",staticStyle:{"position":"relative","top":"-8px"}},[_vm._v("易次元管理系統")])]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","to":"/pages/schedule/daily"}},[_vm._v("日曆")]),_c('v-btn',{attrs:{"text":"","to":"/pages/schedule/monthly"}},[_vm._v("月曆")]),_c('v-btn',{attrs:{"text":"","to":"/pages/schedule/daily"}},[_vm._v("新人預約")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"dropdown-button",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v("客戶資料")])]}}])},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"text":"","to":"/pages/customer/list"}},[_vm._v("客戶列表")])],1),_c('v-list-item',[_c('v-btn',{attrs:{"text":"","to":"/pages/customer/add"}},[_vm._v("新增客戶")])],1)],1)],1),_c('v-btn',{attrs:{"text":"","to":"/pages/payment/pos"}},[_vm._v("POS付款機")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"dropdown-button",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v("付款記錄")])]}}])},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"text":"","to":"/pages/transaction/records"}},[_vm._v("服務付款記錄查詢")])],1),_c('v-list-item',[_c('v-btn',{attrs:{"text":"","to":"/pages/payment/records"}},[_vm._v("商品付款記錄查詢")])],1)],1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"dropdown-button",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v("管理")])]}}])},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"text":"","to":"/pages/product/list"}},[_vm._v("商品")])],1),_c('v-list-item',[_c('v-btn',{attrs:{"text":"","to":"/pages/staff/list"}},[_vm._v("人事")])],1)],1)],1),_c('v-btn',{attrs:{"text":"","to":"/pages/dashboards/stats"}},[_vm._v("曲線")])],1),_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }