<template>
  <v-app>
    <v-main>

      <v-app-bar height="48" color="white" style="margin-bottom: 10px; "  >
        <v-toolbar-title>
            <img src="@/assets/img/logo_0.png" height="40" alt="Logo" />
            <span class="ms-2 font-weight-bold" style="position: relative; top: -8px;">易次元管理系統</span>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn text to="/pages/schedule/daily">日曆</v-btn>
        <v-btn text to="/pages/schedule/monthly">月曆</v-btn>
        <v-btn text to="/pages/schedule/daily">新人預約</v-btn>

        <v-menu offset-y >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="dropdown-button" text v-bind="attrs" v-on="on">客戶資料</v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-btn text to="/pages/customer/list">客戶列表</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn text to="/pages/customer/add">新增客戶</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn text to="/pages/payment/pos">POS付款機</v-btn>

        <v-menu offset-y >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="dropdown-button" text v-bind="attrs" v-on="on">付款記錄</v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-btn text to="/pages/transaction/records">服務付款記錄查詢</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn text to="/pages/payment/records">商品付款記錄查詢</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
        
        <v-menu offset-y >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="dropdown-button" text v-bind="attrs" v-on="on">管理</v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-btn text to="/pages/product/list">商品</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn text to="/pages/staff/list">人事</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
        
        <v-btn text to="/pages/dashboards/stats">曲線</v-btn>

      </v-app-bar>


      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <router-view></router-view>
      </fade-transition>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      

    </v-main>
  </v-app>
</template>
<script>
// /* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import { FadeTransition } from "vue2-transitions";
import Drawer from "@/components/Drawer.vue";
import AppBar from "@/components/AppBar.vue";
import ContentFooter from "@/components/Footer.vue";
import SettingsDrawer from "@/components/Widgets/SettingsDrawer.vue";
//import axios from "axios";
//import axios from "axios";

export default {
  components: {
    ContentFooter,
    FadeTransition,
    Drawer,
    AppBar,
    SettingsDrawer,
  },
  data() {
    return {
      drawer: null,
      showSettingsDrawer: false,
      sidebarColor: "warning",
      sidebarTheme: "white",
      navbarFixed: false,
    };
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("v-navigation-drawer__content");
      }
    },
    toggleSettingsDrawer(value) {
      this.showSettingsDrawer = value;
    },
    updateSidebarColor(value) {
      this.sidebarColor = value;
    },
    updateSidebarTheme(value) {
      let siblings = event.target.closest("button").parentElement.children;
      for (var i = 0; i < siblings.length; i++) {
        siblings[i].classList.remove("bg-gradient-default");
        siblings[i].classList.add("btn-outline-default");
      }
      event.target.closest("button").classList.add("bg-gradient-default");
      event.target.closest("button").classList.remove("btn-outline-default");
      this.sidebarTheme = value;
    },
    toggleNavbarPosition(value) {
      this.navbarFixed = value;
    },
    checkLogin() {
      
      let token = localStorage.getItem("token");

      //alert(token);

      if ( !token || token == null || token == undefined || token.length < 6 ) {
        this.$router.push({ path: "/login" });
        return;
      }

    },
  },
  mounted() {
    this.initScrollbar();
    
    this.checkLogin();

  },
};
</script>
<style lang="scss">
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.08), 0 2px 4px -1px rgba(0, 0, 0, 0.06) ;
}
</style>
<style scoped>
.dropdown-button::after {
  content: '';
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: black transparent transparent transparent;
  margin-left: 5px;
}
</style>