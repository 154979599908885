import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import ProfileLayout from "../views/Layout/ProfileLayout.vue";
import DashboardLayoutVr from "../views/Layout/DashboardLayoutVr.vue";
import PageLayout from "../views/Layout/PageLayout";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";
import AuthCoverLayout from "../views/Layout/AuthCoverLayout";
import AuthIllustrationLayout from "../views/Layout/AuthIllustrationLayout";
import AuthLoginLayout from "../views/Layout/AuthLoginLayout";

const About = () => import("../views/About.vue");
const Test = () => import("@/components/calendar/Main3.vue");
// Dashboard pages
const Dashboard = () => import("../views/Dashboard/Dashboard.vue");
const Discover = () => import("../views/Dashboard/Discover.vue");
const Automotive = () => import("../views/Dashboard/Automotive.vue");
const Sales = () => import("../views/Dashboard/Sales.vue");

const Stats = () => import("../views/Dashboard/Stats.vue");

const Blank = () => import("../views/Dashboard/Blank.vue");
const SmartHome = () => import("../views/Dashboard/SmartHome.vue");
const VrDefault = () => import("../views/Dashboard/VrDefault.vue");
const VrInfo = () => import("../views/Dashboard/VrInfo.vue");
const Crm = () => import("../views/Dashboard/Crm.vue");
const GettingStarted = () => import("../views/Dashboard/GettingStarted.vue");

// 易次元頁面
const ScheduleDaily = () => import("../views/YiSystem/ScheduleDaily3.vue");
const ScheduleMonthly = () => import("../views/YiSystem/ScheduleMonthly.vue");
const ScheduleFull = () => import("../views/YiSystem/ScheduleFull.vue");

//const PaymentRecords = () => import("../views/YiSystem/PaymentRecords.vue");
const PaymentRecords = () => import("../views/YiSystem/OrderRecords.vue");
const TransactionRecords = () => import("../views/YiSystem/TransactionRecords.vue");
const POS = () => import("../views/YiSystem/POS.vue");
const POS2 = () => import("../views/YiSystem/POS2.vue");

const CustomerList = () => import("../views/YiSystem/CustomerList.vue");
const CustomerAdd = () => import("../views/YiSystem/CustomerAdd.vue");
const BodyCondition = () => import("../views/YiSystem/BodyCondition.vue");
const MasterDailyReport = () => import("../views/YiSystem/MasterDailyReport.vue");

const ProductList = () => import("../views/YiSystem/ProductList.vue");
const ProductAdd = () => import("../views/YiSystem/ProductAdd.vue");
const ProductCategoryList = () =>
  import("../views/YiSystem/ProductCategoryList.vue");
const ProductCategoryAdd = () =>
  import("../views/YiSystem/ProductCategoryAdd.vue");

const StaffList = () => import("../views/YiSystem/StaffList.vue");
const StaffAdd = () => import("../views/YiSystem/StaffAdd.vue");

const CarList = () => import("../views/YiSystem/CarList.vue");
const CarAdd = () => import("../views/YiSystem/CarAdd.vue");

// Pages
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");
const Rtl = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Rtl.vue");
const ProfileOverview = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Profile/ProfileOverview.vue"
  );
const Messages = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile/Messages.vue");
const Projects = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile/Projects.vue");
const Reports = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/Reports.vue");
const NewUser = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/NewUser.vue");
const Settings = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Settings.vue");
const Billing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Billing.vue");
const Invoice = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Invoice.vue");
const Timeline = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Projects/Timeline.vue");
const Charts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Charts.vue");
const Alerts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Alerts.vue");
const Notifications = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Notifications.vue");
const SignUpBasic = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignUp/Basic.vue"
  );
const SignUpCover = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignUp/Cover.vue"
  );
const SignUpIllustration = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignUp/Illustration.vue"
  );

const LoginPage = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignUp/Login.vue"
  );

// Applications
const Kanban = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Kanban.vue");
const Wizard = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Wizard.vue");
const Datatables = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Datatables.vue");
const Calendar = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Calendar.vue");

const CalendarTest = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Applications/CalendarTest.vue");

// Ecommerce
const NewProduct = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Products/NewProduct.vue"
  );
const EditProduct = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Products/EditProduct.vue"
  );
const ProductPage = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Products/ProductPage.vue"
  );
const OrderList = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Orders/OrderList.vue"
  );
const OrderDetails = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Orders/OrderDetails.vue"
  );

Vue.use(VueRouter);

let vrPages = {
  path: "/",
  component: DashboardLayoutVr,
  name: "Vr",
  children: [
    {
      path: "/pages/dashboards/vr/vr-default",
      name: "VrDefault",
      component: VrDefault,
      meta: {
        groupName: "Dashboards",
      },
    },
    {
      path: "/pages/dashboards/vr/vr-info",
      name: "VrInfo",
      component: VrInfo,
      meta: {
        groupName: "Dashboards",
      },
    },
  ],
};

let profilePages = {
  path: "/",
  component: ProfileLayout,
  name: "Profile",
  children: [
    {
      path: "/pages/pages/profile/overview",
      name: "ProfileOverview",
      component: ProfileOverview,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/profile/messages",
      name: "Messages",
      component: Messages,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/profile/projects",
      name: "Project",
      component: Projects,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let userPages = {
  path: "/",
  component: DashboardLayout,
  name: "Users",
  children: [
    {
      path: "/pages/pages/users/reports",
      name: "Reports",
      component: Reports,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/users/new-user",
      name: "NewUser",
      component: NewUser,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let accountPages = {
  path: "/",
  component: DashboardLayout,
  name: "Account",
  children: [
    {
      path: "/pages/pages/account/settings",
      name: "Settings",
      component: Settings,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/account/billing",
      name: "Billing",
      component: Billing,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/account/invoice",
      name: "Invoice",
      component: Invoice,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let projectsPages = {
  path: "/",
  component: DashboardLayout,
  name: "Projects",
  children: [
    {
      path: "/pages/pages/projects/timeline",
      name: "Timeline",
      component: Timeline,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let applicationPages = {
  path: "/",
  component: DashboardLayout,
  name: "Application",
  children: [
    {
      path: "/pages/applications/kanban",
      name: "Kanban",
      component: Kanban,
      meta: {
        groupName: "Applications",
      },
    },
    {
      path: "/pages/applications/wizard",
      name: "Wizard",
      component: Wizard,
      meta: {
        groupName: "Applications",
      },
    },
    {
      path: "/pages/applications/datatables",
      name: "Datatables",
      component: Datatables,
      meta: {
        groupName: "Applications",
      },
    },
    {
      path: "/pages/applications/calendar",
      name: "Calendar",
      component: Calendar,
      meta: {
        groupName: "Applications",
      },
    },
  ],
};

let pricingPage = {
  path: "/",
  component: PageLayout,
  name: "Pricing Page",
  children: [
    {
      path: "/pages/pages/pricing-page",
      name: "Pricing",
      component: Pricing,
    },
  ],
};

let authBasicPages = {
  path: "/",
  component: AuthBasicLayout,
  name: "Authentication Basic",
  children: [
    {
      path: "/pages/authentication/signup/basic",
      name: "SignUpBasic",
      component: SignUpBasic,
    },
  ],
};

let authCoverPages = {
  path: "/",
  component: AuthCoverLayout,
  name: "Authentication Cover",
  children: [
    {
      path: "/pages/authentication/signup/cover",
      name: "SignUpCover",
      component: SignUpCover,
    },
  ],
};

let authIllustrationPages = {
  path: "/",
  component: AuthIllustrationLayout,
  name: "Authentication Illustration",
  children: [
    {
      path: "/pages/authentication/signup/illustration",
      name: "SignUpIllustration",
      component: SignUpIllustration,
    },
  ],
};

const routes = [
  {
    path: "/About",
    name: "About",
    component: About,
  },
  {
    path: "/Test",
    name: "Test",
    component: Test,
  },
  {
    path: "/",
    component: AuthLoginLayout,
    name: "Login",
    children: [
      {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
      },
    ],
    
  },
  
  {
    path: "/",
    name: "Dashboard",
    redirect: "/pages/dashboards/analytics",
    component: DashboardLayout,
    children: [
      {
        path: "pages/dashboards/analytics",
        name: "Analytics",
        component: Dashboard,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "pages/dashboards/discover",
        name: "Discover",
        component: Discover,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/smart-home",
        name: "SmartHome",
        component: SmartHome,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/crm",
        name: "Crm",
        component: Crm,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/dashboards/automotive",
        name: "Automotive",
        component: Automotive,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/sales",
        name: "Sales",
        component: Sales,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/stats",
        name: "Stats",
        component: Stats,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/blank",
        name: "Blank",
        component: Blank,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/pages/rtl",
        name: "RTL",
        component: Rtl,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/charts",
        name: "Charts",
        component: Charts,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/alerts",
        name: "Alerts",
        component: Alerts,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/notifications",
        name: "Notifications",
        component: Notifications,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "getting-started",
        name: "Getting Started",
        component: GettingStarted,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/ecommerce/products/new-product",
        name: "New Product",
        component: NewProduct,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/products/edit-product",
        name: "Edit Product",
        component: EditProduct,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/products/product-page",
        name: "Product Page",
        component: ProductPage,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/orders/list",
        name: "Order List",
        component: OrderList,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/orders/details",
        name: "Order Details",
        component: OrderDetails,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/schedule/daily",
        name: "Schedule Daily",
        component: ScheduleDaily,
        meta: {
          groupName: "排程管理",
        },
      },
      {
        path: "/pages/schedule/fullscreen",
        name: "Schedule Full Screen",
        component: ScheduleFull,
        meta: {
          groupName: "排程管理",
        },
      },
      {
        path: "/pages/schedule/daily_test",
        name: "Schedule Daily Test",
        component: CalendarTest,
        meta: {
          groupName: "排程管理",
        },
      },
      {
        path: "/pages/schedule/monthly",
        name: "Schedule Monthly",
        component: ScheduleMonthly,
        meta: {
          groupName: "排程管理",
        },
      },
      {
        path: "/pages/payment/pos",
        name: "POS",
        component: POS,
        meta: {
          groupName: "付款專區",
        },
      },
      {
        path: "/pages/payment/pos2",
        name: "POS2",
        component: POS2,
        meta: {
          groupName: "付款專區2",
        },
      },
      {
        path: "/pages/payment/records",
        name: "Payment Records",
        component: PaymentRecords,
        meta: {
          groupName: "付款專區",
        },
      },
      {
        path: "/pages/transaction/records",
        name: "Transaction Records",
        component: TransactionRecords,
        meta: {
          groupName: "付款專區",
        },
      },
      {
        path: "/pages/customer/list",
        name: "Customer List",
        component: CustomerList,
        meta: {
          groupName: "客戶管理",
        },
      },
      {
        path: "/pages/customer/add",
        name: "新增客戶",
        component: CustomerAdd,
        meta: {
          groupName: "客戶管理",
        },
      },
      {
        path: "/pages/customer/body_condition/:customer_id",
        name: "身體狀況",
        component: BodyCondition,
        meta: {
          groupName: "身體狀況",
        },
      },
      {
        path: "/pages/customer/master_daily_report/:customer_id",
        name: "師傅每日填寫專區",
        component: MasterDailyReport,
        meta: {
          groupName: "師傅每日填寫專區",
        },
      },
      {
        path: "/pages/product/list",
        name: "Product List",
        component: ProductList,
        meta: {
          groupName: "商品管理",
        },
      },
      {
        path: "/pages/product/add",
        name: "Product Add",
        component: ProductAdd,
        meta: {
          groupName: "商品管理",
        },
      },
      {
        path: "/pages/product_category/list",
        name: "Product Category List",
        component: ProductCategoryList,
        meta: {
          groupName: "商品管理",
        },
      },
      {
        path: "/pages/product_category/add",
        name: "Product Category Add",
        component: ProductCategoryAdd,
        meta: {
          groupName: "商品管理",
        },
      },
      {
        path: "/pages/staff/list",
        name: "Staff List",
        component: StaffList,
        meta: {
          groupName: "內部管理專區",
        },
      },
      {
        path: "/pages/staff/add",
        name: "Staff Add",
        component: StaffAdd,
        meta: {
          groupName: "內部管理專區",
        },
      },
      {
        path: "/pages/car/list",
        name: "Car List",
        component: CarList,
        meta: {
          groupName: "汽車管理",
        },
      },
      {
        path: "/pages/car/add",
        name: "Car Add",
        component: CarAdd,
        meta: {
          groupName: "汽車管理",
        },
      },
    ],
  },
  pricingPage,
  profilePages,
  applicationPages,
  userPages,
  accountPages,
  projectsPages,
  vrPages,
  authBasicPages,
  authCoverPages,
  authIllustrationPages,
];

const router = new VueRouter({
  routes,
});

export default router;
