import Vue from "vue";
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    test: 'test data',
    token: '',
    userId: '',
    //userName: '',
    user: '',
  },
  mutations: {
    setToken(state, token) {
        state.token = token;
        
    },
    setUserId(state, userId) {
        state.userId = userId;
        
    },
    // setUserName(state, userName) {
    //     state.userName = userName
    //     }
    // },
    setUser(state, user) {
        state.user = user
    },
  },
    actions: {
        setToken({ commit }, token) {
            commit('setToken', token);
        }
    },
    getters: {
        getToken(state) {
            return state.token;
        }
    }

   
})