/*
=========================================================
* Vuetify Material Dashboard 2 PRO - v3.0.0
=========================================================

* Product Page:  https://www.creative-tim.com/product/vuetify-material-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";

import axios from "axios";
import VueAxios from "vue-axios";

import BusinessHours from 'vue-business-hours';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret, faPhone, faStickyNote } from '@fortawesome/free-solid-svg-icons'


/* add icons to the library */
library.add(faUserSecret,faPhone,faStickyNote)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

import store from './store'
//Vue.use(store);

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);

// plugin setup
Vue.use(DashboardPlugin);

Vue.use(BusinessHours);

fetch(process.env.BASE_URL + "config.json")
    .then((response) => response.json())
    .then((config) => {

      Vue.prototype.$apiUrl = "http://yi-system.cglandmark.net:8008";
      Vue.prototype.$apiUrl = "http://localhost:8008";
      //Vue.prototype.$apiUrl = 'http://172.104.175.129:8008';

      Vue.prototype.$config = config;

      Vue.prototype.$apiUrl = config.apiUrl;

        new Vue({
        router,
        vuetify,
        store,
        render: (h) => h(App),
      }).$mount("#app");
      

    })

